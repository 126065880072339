<script>
	export let text;
	export let onClick;
</script>

<style>

</style>

<button type="button" class="btn btn-primary" on:click={onClick}>{text}</button>
