<script>
    import LoginButton from "../components/Buttons.svelte"
    import { navigate} from "svelte-routing";
    import {login as loginWithCognito} from '../auth/authService'

    async function login() {
        const res = await loginWithCognito();
        navigate("/buzzer");
    }
</script>
<LoginButton text="Login" onClick={login} />