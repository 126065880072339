<script>
    import { Router, Link, Route } from "svelte-routing";
    import Login from "./pages/Login.svelte";
    import Buzzer from "./pages/Buzzer.svelte";

    export let url = "";
</script>
<style>
    :global(body) {
        background-color: #000;
        text-align:center;
    }
    h1 {
        color: white;
    }

</style>
<Router url="{url}">
    <h1>Cornetto Time Tracker</h1>
    <Route path="buzzer" component="{Buzzer}" />
    <Route path="/"><Login /></Route>
</Router>